<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="fs-18 c-9 fw-7">基本信息</p>
      <el-form
        class="mt-24"
        label-width="100px"
        :rules="rules"
        :model="comm_form"
        ref="comm_form"
      >
        <el-form-item label="商品标题" prop="goodsName">
          <el-input
            class="form-input"
            placeholder="请输入商品标题"
            v-model="comm_form.goodsName"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类" prop="goodsClassifyId">
          <el-cascader
            class="form-input"
            placeholder="请选择商品分类"
            v-model="comm_form.goodsClassifyId"
            :options="classificationList"
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">详细信息</p>
        <el-form-item label="商品条形码" prop="barCode">
          <!-- :disabled="String(flag) == 'false'"  先解除禁用修改-->
          <el-input
            class="form-input"
            placeholder="输入条形码"
            v-model="comm_form.barCode"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="所属门店" prop="storeId">
          <el-select
            placeholder="请选择所属门店"
            v-model="comm_form.storeId"
            @change="storeChange"
            class="form-input"
          >
            <el-option
              v-for="(item, index) in store_list"
              :key="index"
              :value="item.id"
              :label="item.storeName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属商户"
          prop="merchantId"
          v-show="comm_form.storeId"
        >
          <el-select
            v-model="comm_form.merchantId"
            filterable
            placeholder="请选择所属商户"
            class="form-input"
          >
            <el-option
              v-for="(item,index) in merchantList"
              :key="index"
              :label="item.merchantName"
              :value="String(item.merchantId)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            placeholder="请输入排序数字"
            class="form-input"
            v-model.number="comm_form.sort"
          ></el-input>
        </el-form-item>
        <el-form-item label="虚拟销量" prop="ficti">
          <el-input
            placeholder="请输入虚拟销量"
            class="form-input"
            v-model.number="comm_form.ficti"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品主图" prop="imgUrl">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'400*400'"
            :isDrag="isDrage"
            :imgsPreview="mainImgStr"
            @getImgData="getImgData"
          />
        </el-form-item>
        <el-form-item label="商品辅图" prop="pictures">
          <UploadPics
            :uploadType="'pics'"
            :imgLimit="6"
            :isDrag='isDrage'
            :imgSize="'400*400，图片底部预留60px'"
            :imgsPreview="subImgStr"
            @getImgData="getImgData"
          />
        </el-form-item>
        <el-form-item label="商品规格" prop="specType">
          <el-radio-group v-model="comm_form.specType" @change="changeSpec">
            <el-radio :label="0">无规格</el-radio>
            <el-radio :label="1">多规格</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 添加子规格-->
        <el-row v-if="comm_form.specType === 1" class="noForm">
          <el-form-item>
            <template v-for="(item, index) in attrs">
              <div v-if="item.isDel != 1" :key="index">
                <div class="acea-row row-middle">
                  <span class="mr5">{{ item.value }}</span>
                  <i
                    class="el-icon-circle-close"
                    @click="handleRemoveRole(item, index)"
                  ></i>
                </div>
                <div class="rulesBox">
                  <el-tag
                    closable
                    color="primary"
                    v-for="(j, indexn) in item.detail"
                    :key="indexn"
                    :name="j"
                    class="mr20"
                    @close="handleRemove2(item.detail, indexn)"
                    >{{ j }}</el-tag
                  >
                  <el-input
                    placeholder="请输入属性名称"
                    v-model="item.detail.attrsVal"
                    @input="ruleChange('attrsVal', index)"
                    style="width: 200px"
                  >
                    <el-button
                      slot="append"
                      type="primary"
                      @click="
                        createAttr(item.detail.attrsVal, index, item.detail)
                      "
                      >添加</el-button
                    >
                  </el-input>
                </div>
              </div>
            </template>
          </el-form-item>
        </el-row>

        <!-- 添加主规格-->
        <el-row v-if="showFormatEdit">
          <el-col :span="8">
            <el-form-item label="规格" props="attrsName">
              <el-input
                placeholder="请输入规格"
                v-model="comm_form.attrsName"
                @input="ruleChange('attrsName')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格值：" props="attrsVal">
              <el-input
                v-model="comm_form.attrsVal"
                placeholder="请输入规格值"
                @input="ruleChange('attrsVal')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button type="primary" @click="createAttrName">确定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="danger" @click="offAttrName">取消</el-button>
          </el-col>
        </el-row>

        <!-- 规格相关按钮-->
        <el-row v-if="createFormatBtn">
          <el-form-item>
            <el-button type="primary" @click="addBtn" class="mr15"
              >添加新规格</el-button
            >
            <el-button type="success" @click="generate">立即生成</el-button>
          </el-form-item>
        </el-row>

        <!-- 多规格属性设置-->
        <el-form-item label="商品属性" class="labeltop" v-if="isGenerate">
          <el-table :data="comm_form.goodsInfo" border>
            <el-table-column
              type="myindex"
              v-for="(item, index) in headerMany"
              :key="index"
              :label="item.title"
              :property="item.slot"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                {{ item.title }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="体积重量的计算方法为：体积重量=长(cm)×宽(cm)×高(cm)÷6000"
                  placement="top-start"
                  v-if="scope.column.property == 'weight'"
                >
                  <i class="el-icon-question" />
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div v-if="scope.column.property == 'imgUrl'">
                  <el-form-item
                    :prop="'goodsInfo[' + scope.$index + '].imgUrl'"
                    :rules="[
                      {
                        validator: (x, y, z) => myValidate(x, y, z, 'imgUrl'),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <UploadPicsSmall
                      :uploadType="'pics'"
                      imgSize="50*50"
                      :isStyleChange="true"
                      :dataPic="scope.row"
                      :imgLimit="1"
                      :isDrag='isDrage'
                      :imgsPreview="scope.row[scope.column.property]"
                      @getImgData="getSmallData"
                      :hideText="true"
                    />
                  </el-form-item>
                </div>
                <div
                  v-else-if="
                    scope.column.property.indexOf('value') != -1 ||
                    scope.column.property == 'sku'
                  "
                >
                  {{ scope.row[scope.column.property] }}
                </div>
                <div v-else-if="scope.column.property == 'action'">
                  <el-button type="text" size="mini" @click="delAttrTable(scope.row, scope.$index)">删除</el-button>
                  <el-button type="text" size="mini" @click="copyLastTable(scope.row, scope.$index)" v-if="scope.$index != 0">复制上一条</el-button>
                </div>
                <div v-else>
                  <el-form-item
                    :prop="
                      'goodsInfo[' +
                      scope.$index +
                      '][' +
                      scope.column.property +
                      ']'
                    "
                    :rules="[
                      {
                        validator: (x, y, z) =>
                          myValidate(x, y, z, scope.column.property),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input v-model="scope.row[scope.column.property]" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <!-- 单规格属性设置 -->
        <el-form-item
          label="商品属性"
          class="labeltop"
          v-if="comm_form.specType == 0"
        >
          <el-table :data="comm_form.goodsInfo" border>
            <el-table-column
              type="myindex"
              v-for="(item, index) in headerOne"
              :key="index"
              :label="item.title"
              :property="item.slot"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                {{ item.title }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="体积重量的计算方法为：体积重量=长(cm)×宽(cm)×高(cm)÷6000"
                  placement="top-start"
                  v-if="scope.column.property == 'weight'"
                >
                  <i class="el-icon-question" />
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div v-if="scope.column.property == 'imgUrl'">
                  
                  <el-form-item
                    :prop="'goodsInfo[' + scope.$index + '].imgUrl'"
                    :rules="[
                      {
                        validator: (x, y, z) => myValidate(x, y, z, 'imgUrl'),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <UploadPicsSmall
                      :uploadType="'pics'"
                      imgSize="50*50"
                      :isStyleChange="true"
                      :dataPic="scope.row"
                      :imgLimit="1"
                      :isDrag='isDrage'
                      :imgsPreview="scope.row[scope.column.property]"
                      @getImgData="getSmallData"
                      :hideText="true"
                    />
                  </el-form-item>
                </div>
                <div v-else-if="scope.column.property.indexOf('value') != -1">
                  {{ scope.row[scope.column.property] }}
                
                </div>
                <div v-else>
               
                  <el-form-item
                    :prop="
                      'goodsInfo[' +
                      scope.$index +
                      '][' +
                      scope.column.property +
                      ']'
                    "
                    :rules="[
                      {
                        validator: (x, y, z) =>
                          myValidate(x, y, z, scope.column.property),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input v-model="scope.row[scope.column.property]" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="配送方式" prop="deliveryMethod">
          <el-checkbox
            v-model="comm_form.deliveryMethod"
            :label="0"
            @change="deliveryMethodChange(0)"
            >自提</el-checkbox
          >
          <el-checkbox
            v-model="comm_form.deliveryMethod"
            :label="1"
            @change="deliveryMethodChange(1)"
            >邮寄</el-checkbox
          >
          <el-select
            v-model="comm_form.deliveryTempId"
            placeholder="请选择邮寄模板"
            v-if="comm_form.deliveryMethod.indexOf(1) > -1"
            class="ml-20"
          >
            <el-option
              v-for="item in deliveryMethodTemplateList"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品详情介绍">
          <tinymce v-model="comm_form.goodsDescribe" :height="300" />
        </el-form-item>
        <el-form-item label="商品状态" prop="status">
          <el-radio v-model="comm_form.status" :label="1">上架</el-radio>
          <el-radio v-model="comm_form.status" :label="0">下架</el-radio>
        </el-form-item>
        <el-form-item label="推荐2区" prop="isRecommendArea">
          <el-radio v-model="comm_form.isRecommendArea" :label="0"
            >关闭</el-radio
          >
          <el-radio v-model="comm_form.isRecommendArea" :label="1"
            >开启</el-radio
          >
          <el-form-item
            label="排序"
            prop="sortRecommendArea"
            v-if="comm_form.isRecommendArea == 1"
            class="inline"
            label-width="60px"
          >
            <el-input v-model="comm_form.sortRecommendArea" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="热卖单品" prop="isHot">
          <el-radio v-model="comm_form.isHot" :label="0">关闭</el-radio>
          <el-radio v-model="comm_form.isHot" :label="1">开启</el-radio>
          <el-form-item
            label="排序"
            prop="sortHot"
            v-if="comm_form.isHot == 1"
            class="inline"
            label-width="60px"
          >
            <el-input v-model="comm_form.sortHot" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="猜你喜欢" prop="isLike">
          <el-radio v-model="comm_form.isLike" :label="0">关闭</el-radio>
          <el-radio v-model="comm_form.isLike" :label="1">开启</el-radio>
          <el-form-item
            label="排序"
            prop="sortLike"
            v-if="comm_form.isLike == 1"
            class="inline"
            label-width="60px"
          >
            <el-input v-model="comm_form.sortLike" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="首发新品" prop="isNew">
          <el-radio v-model="comm_form.isNew" :label="0">关闭</el-radio>
          <el-radio v-model="comm_form.isNew" :label="1">开启</el-radio>
          <el-form-item
            label="排序"
            prop="sortNew"
            v-if="comm_form.isNew == 1"
            class="inline"
            label-width="60px"
          >
            <el-input v-model="comm_form.sortNew" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="大牌推荐" prop="isRecommend">
          <el-radio v-model="comm_form.isRecommend" :label="0">关闭</el-radio>
          <el-radio v-model="comm_form.isRecommend" :label="1">开启</el-radio>
          <el-form-item
            label="排序"
            prop="sortRecommend"
            v-if="comm_form.isRecommend == 1"
            class="inline"
            label-width="60px"
          >
            <el-input v-model="comm_form.sortRecommend" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="多买折扣" prop="isBuyMoreDiscount">
          <el-radio
            v-model="comm_form.isBuyMoreDiscount"
            :label="0"
            @change="buyMoreDiscountChange"
            >关闭</el-radio
          >
          <el-radio
            v-model="comm_form.isBuyMoreDiscount"
            :label="1"
            @change="buyMoreDiscountChange"
            >开启</el-radio
          >
          <el-row v-if="comm_form.isBuyMoreDiscount == 1">
            <el-row
              v-for="(item, index) in comm_form.buyMoreDiscount"
              :key="index"
              class="mt-20"
            >
              <el-col :span="1" v-if="index == 0" class="fs-30 mt-5">
                <i
                  class="el-icon-circle-plus-outline"
                  @click="addMoreDiscountBtn"
                />
              </el-col>
              <!-- <el-col :span="3" :offset="index == 0 ? 0 : 1">
                <el-form-item
                  :prop="
                    'buyMoreDiscount[' + index + '].buyMoreDiscountStartTime'
                  "
                  :rules="[
                    {
                      message: '开始时间不能为空',
                      required: true,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    class="autoWidth"
                    v-model="item.buyMoreDiscountStartTime"
                    type="date"
                    placeholder="请选择开始时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="3" :offset="1">
                <el-form-item
                  :prop="
                    'buyMoreDiscount[' + index + '].buyMoreDiscountEndTime'
                  "
                  :rules="[
                    {
                      validator: (x, y, z) =>
                        myValidate(
                          x,
                          y,
                          z,
                          'buyMoreDiscountEndTime',
                          item.buyMoreDiscountStartTime
                        ),
                      required: true,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    class="autoWidth"
                    v-model="item.buyMoreDiscountEndTime"
                    type="date"
                    placeholder="请选择结束时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col> -->
              <el-col :span="4" :offset="index!=0? 1:0">
                <el-form-item
                  label="购买数量"
                  :prop="'buyMoreDiscount[' + index + '].buyMoreDiscountNum'"
                  :rules="[
                    {
                      validator: (x, y, z) =>
                        myValidate(x, y, z, 'buyMoreDiscountNum'),
                      required: true,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input v-model="item.buyMoreDiscountNum" />
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="商品折扣"
                  :prop="
                    'buyMoreDiscount[' + index + '].buyMoreDiscountDiscount'
                  "
                  :rules="[
                    {
                      validator: (x, y, z) =>
                        myValidate(x, y, z, 'buyMoreDiscountDiscount'),
                      required: true,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input v-model="item.buyMoreDiscountDiscount" />
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <span class="ml-10 c-9 fs-16">请输入0.1-9.9的数值</span>
              </el-col>
            </el-row>
          </el-row>
        </el-form-item>
        <el-form-item label="单活动商品" prop="isActivity">
          <el-radio v-model="comm_form.isActivity" :label="0">关闭</el-radio>
          <el-radio v-model="comm_form.isActivity" :label="1">开启</el-radio>
          <span class="ml-20 c-9 fs-16"
            >开启后您的商品不展示于百货中，只参与活动。</span
          >
        </el-form-item>
      </el-form>
      <!-- 按钮区域 -->
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="$router.go(-1)"
          >取消</el-button
        >
        <el-button
          class="form-button"
          type="primary"
          @click.native.prevent="submit"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics";
import UploadPicsSmall from "@/components/UploadPics_small";
import {
  subProduct,
  getStoreList,
  getTypeList,
  getShippingList,
  getProductDetail,
  getMerchantsListByStoreId,
  generateSpecification,
} from "@/api/online_department_store/commodity_manage";
import { getProcudtCateListAll } from "@/api/online_department_store/categories_manage";
export default {
  components: {
    Tinymce,
    UploadPics,
    UploadPicsSmall,
  },
  data() {
    let myValidate = (rule, value, callback, str, value2) => {
      switch (str) {
        case "pic":
        case "imgUrl":
          if (!value) {
            callback(new Error("不能为空"));
          } else {
            callback();
          }
          break;
        case "price":
        case 'markPrice':
        case "volume":
        case "weight":
        case "ot_price":
        case "cost":
          if (!/^[\d]+(\.[\d]{1,2})?$/.test(value)) {
            callback(new Error("不超过两位小数的数字"));
          } else if (value === undefined || value === null || value === "") {
            callback(new Error("不能为空"));
          } else {
            callback();
          }
          break;
        case "buyMoreDiscountDiscount":
          if (!/^[\d](\.[\d])?$/.test(value)) {
            callback(new Error("格式不对"));
          } else if (value === undefined || value === null || value === "") {
            callback(new Error("多买折扣不能为空"));
          } else {
            callback();
          }
          break;
        case "buyMoreDiscountEndTime":
          if (!value) {
            callback(new Error("不能为空"));
          } else if (new Date(value).getTime() <= new Date(value2).getTime()) {
            callback(new Error("结束时间不能小于开始时间"));
          } else {
            callback();
          }
          break;
        default:
          if (!/^[1-9]\d*$/.test(value)) {
            callback(new Error("正整数"));
          } else if (value === undefined || value === null || value === "") {
            callback(new Error("不能为空"));
          } else {
            callback();
          }
          break;
      }
    };

    return {
      isDrage:true,
      comm_form: {
        goodsName: "",
        barCode: "",
        goodsClassifyId: "",
        attrsName: "",
        attrsVal: "",
        merchantId: "",
        storeId: "",
        price: null,
        markPrice: null,
        stock: null,
        imgUrl: "",
        specType: null,
        pictures: "",
        status: null,
        isHot: null,
        isLike: null,
        isNew: null,
        isRecommend: null,
        isBuyMoreDiscount: null,
        isActivity: null,
        isRecommendArea: null,
        ficti: "",
        sort: "",
        sortRecommend: "",
        sortHot: "",
        sortLike: "",
        sortNew: "",
        sortRecommendArea: "",
        buyMoreDiscount: [],
        goodsDescribe: ``,
        deliveryMethod: [],
        deliveryTempId: "",
        goodsInfo: [],
        manySpecInfo: [],
      },
      classificationList: [],
      merchantList: [],
      rules: {
        goodsName: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        barCode: [
          { required: true, message: "条形码不能为空", trigger: "blur" },
        ],
        storeId: [{ required: true, message: "门店不能为空", trigger: "blur" }],
        goodsClassifyId: [
          { required: true, message: "商品分类不能为空", trigger: "blur" },
        ],
        merchantId: [
          { required: true, message: "商户不能为空", trigger: "blur" },
        ],
        price: [
          { required: true, message: "实价不能为空", trigger: "change" },
          {
            pattern: /^[\d]+(\.[\d]{2})?$/, 
            message: "不能超过两位小数的数字",
            trigger: "change",
          },
        ],
        markPrice: [
          { required: true, message: "原价不能为空", trigger: "change" },
          {
            pattern: /^[\d]+(\.[\d]{2})?$/,
            message: "不能超过两位小数的数字",
            trigger: "change",
          },
        ],
        stock: [
          { required: true, message: "库存不能为空", trigger: "change" },
          { pattern: /^\d+$/, message: "只能为数字", trigger: "change" },
        ],
        imgUrl: [
          { required: true, message: "商品主图不能为空", trigger: "blur" },
        ],
        pictures: [
          { required: true, message: "商品辅图不能为空", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择商品状态" }],
        isHot: [{ required: true, message: "请选择热卖单品" }],
        isLike: [{ required: true, message: "请选择猜你喜欢" }],
        isNew: [{ required: true, message: "请选择首发新品" }],
        isRecommend: [{ required: true, message: "请选择大牌推荐" }],
        isBuyMoreDiscount: [{ required: true, message: "请选择多买折扣" }],
        isActivity: [{ required: true, message: "请选择单活动商品" }],
        isRecommendArea: [{ required: true, message: "请选择推荐2区" }],
        specType: [{ required: true, message: "请选择商品规格" }],
        deliveryMethod: [{ required: true, message: "请选择配送方式" }],
        deliveryTempId: [{ required: true, message: "请选择邮寄模板" }],
        ficti: [
          { required: true, message: "销量不能为空", trigger: "change" },
          { pattern: /^\d+$/, message: "只能为数字", trigger: "change" },
        ],
        sort: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正整数",
            trigger: "change",
          },
        ],
        sortRecommend: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正整数",
            trigger: "change",
          },
        ],
        sortHot: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正整数",
            trigger: "change",
          },
        ],
        sortLike: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正整数",
            trigger: "change",
          },
        ],
        sortNew: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正整数",
            trigger: "change",
          },
        ],
        sortRecommendArea: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正整数",
            trigger: "change",
          },
        ],
      },
      headerOne: [
        {
          title: "图片",
          slot: "imgUrl",
          align: "center",
          minWidth: 80,
        },
        {
          title: "售价",
          slot: "price",
          align: "center",
          minWidth: 80,
        },
        {
          title: "原价",
          slot: "markPrice",
          align: "center",
          minWidth: 80,
        },
        {
          title: "库存",
          slot: "stock",
          align: "center",
          minWidth: 80,
        },
        {
          title: "重量（KG）",
          slot: "weight",
          align: "center",
          minWidth: 80,
        },
        {
          title: "体积（m³）",
          slot: "volume",
          align: "center",
          minWidth: 80,
        },
      ],
      headerMany: [
        {
          title: "规格1",
          slot: "value1",
          align: "center",
          minWidth: 80,
        },
        {
          title: "规格2",
          slot: "value2",
          align: "center",
          minWidth: 80,
        },
        {
          title: "图片",
          slot: "imgUrl",
          align: "center",
          minWidth: 80,
        },
        {
          title: "售价",
          slot: "price",
          align: "center",
          minWidth: 80,
        },
        {
          title: "原价",
          slot: "markPrice",
          align: "center",
          minWidth: 80,
        },
        {
          title: "库存",
          slot: "stock",
          align: "center",
          minWidth: 80,
        },
        {
          title: "商品编号",
          slot: "sku",
          align: "center",
          minWidth: 80,
        },
        {
          title: "重量（KG）",
          slot: "weight",
          align: "center",
          minWidth: 80,
        },
        {
          title: "体积（m³）",
          slot: "volume",
          align: "center",
          minWidth: 80,
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          minWidth: 80,
        },
      ],
      myValidate: myValidate,
      type_list: [],
      store_list: [],
      deliveryMethodTemplateList: [],
      mainImgStr: "",
      subImgStr: "",
      flag: null,
      attrs: [],
      isGenerate: false,
      createFormatBtn: false,
      showFormatEdit: false,
    };
  },
  watch: {
    "comm_form.manySpecInfo": {  
      handler(newValue, old) {
        if (newValue && newValue.length == this.comm_form.goodsInfo.length) {
          this.comm_form.goodsInfo = JSON.parse(JSON.stringify(newValue));
        } else {
          if(!newValue) return
          this.comm_form.goodsInfo.forEach((ele, index) => {
            newValue.forEach((e) => {
              if (ele.value1 == e.value1 && ele.value2 == e.value2) {
                this.$set(this.comm_form.goodsInfo, index, e);
              }
            });
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.flag = this.$route.query.flag;
    console.log(this.$route.query.flag)
    if (this.flag == false || this.flag == "false") {
      this.refillData(this.$route.query.id);
     
    }
    this.getType();
    this.getStores();
    this.getCateList();
    this.getDeliveryMethod();
    
  },
  mounted() {},
  methods: {
    // 商品信息回填

    refillData(e) {
      getProductDetail(e).then((res) => {
        let data = res.data.body;
        console.log(data)
        Object.assign(data, {
          attrsName: "",
          attrsVal: "",
        });
        //回填配送方式
        data.deliveryMethod =
          data.deliveryType === 0
            ? [0]
            : data.deliveryType === 1
            ? [1]
            : data.deliveryType === 2
            ? [0, 1]
            : [];
        let attrs = []; //回填规格属性
        data.goodsSpec.forEach((ele) => {
          attrs.push({
            isDel: ele.isDel,
            id: ele.id,
            goodId: ele.goodId,
            value: ele.specName,
            detail: ele.specValues.split(","),
          });
        });
        this.attrs = attrs;

        data.buyMoreDiscount = JSON.parse(data.buyMoreDiscount);
        if (data.specType == 1) {
          data.goodsInfo.forEach((ele) => {
            //回填规格属性信息
            ele.value1 = (ele.productSpec && ele.productSpec.length>0) ? ele.productSpec.split(",")[0] : '';
            ele.value2 = (ele.productSpec && ele.productSpec.length>1) ? ele.productSpec.split(",")[1] : '';
          });
          data.manySpecInfo = JSON.parse(JSON.stringify(data.goodsInfo));
          this.isGenerate = true;
          this.createFormatBtn = true;
        }
        data.merchantName  = data.merchant.merchantName;
        data.merchantId = String(data.merchantId)
        console.log(data)
        this.comm_form = data;
        console.log(this.comm_form)
        this.mainImgStr = this.comm_form.imgUrl;
        this.subImgStr = this.comm_form.pictures;
         this.getMerchantsListHandle();  //查询门店的商户
      });
    },
    handleChange(value) {
      //商品分类改变
    },
    ruleChange(str, index) {
      if (typeof index == "number") {
        this.$set(
          this.attrs[index].detail,
          str,
          this.attrs[index].detail[str].replace(/,/g, "，")
        );
      } else {
        this.$set(this.comm_form, str, this.comm_form[str].replace(/,/g, "，"));
      }
    },
    // 获取商品品类
    getType() {
      getTypeList().then((res) => {
        this.type_list = res.data.body;
      });
    },
    // 获取丽日商品分类
    getCateList() {
      getProcudtCateListAll({ filter: 1 }).then((res) => {
        if (res.data.code == 200) {
          this.classificationList = res.data.body;
        }
      });
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.store_list = res.data.body.list;
      });
    },
    //获取邮寄模板列表
    getDeliveryMethod() {
      let data = {
        pageSize:9999,
        pageNum:1
      };
      getShippingList(data).then((res) => {
        this.deliveryMethodTemplateList = res.data.body.list;
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      if (!this.comm_form.storeId) return
      getMerchantsListByStoreId({ shopId: this.comm_form.storeId }).then((res) => {
        console.log(res.data.body)
        if (res.data.body && res.data.body.length) {
          this.merchantList = res.data.body;
        }
      });
    },
    storeChange() {
      //门店改变
      this.getMerchantsListHandle();
      this.comm_form.merchantId = "";
    },
    getImgData(imgData, type) {
      if (type == "main") this.comm_form.imgUrl = imgData.mainImg;
      if (type == "pics") this.comm_form.pictures = imgData.subImgs;
    },
    getSmallData(imgData, dataPic) {
      dataPic.imgUrl = imgData.join(",");
    },
    // 改变商品规格
    changeSpec(a) {
      if (this.comm_form.specType == 1) { //多规则
        this.createFormatBtn = true;
        this.showFormatEdit = true;
        this.$set(this.comm_form, "goodsInfo", []);
      } else {  //单规格
        this.isGenerate = false;
        this.createFormatBtn = false;
        this.showFormatEdit = false;
        this.$set(this.comm_form, "goodsInfo", [
          {
            imgUrl: "",
            price: 0,
            markPrice: 0,
            stock: 0,
            sku: "",
            weight: "",
            isDel: 0,
          },
        ]);
      }
    },
    // 删除表格中的属性
    delAttrTable(row, ind) {
      this.comm_form.goodsInfo.splice(ind, 1);
    },
    copyLastTable(row,ind) {
      console.log(row,ind)
      this.$set(this.comm_form.goodsInfo[ind],'imgUrl',this.comm_form.goodsInfo[ind-1].imgUrl)
      this.$set(this.comm_form.goodsInfo[ind],'price',this.comm_form.goodsInfo[ind-1].price)
      this.$set(this.comm_form.goodsInfo[ind],'markPrice',this.comm_form.goodsInfo[ind-1].markPrice)
      this.$set(this.comm_form.goodsInfo[ind],'stock',this.comm_form.goodsInfo[ind-1].stock)
      this.$set(this.comm_form.goodsInfo[ind],'weight',this.comm_form.goodsInfo[ind-1].weight)
      this.$set(this.comm_form.goodsInfo[ind],'volume',this.comm_form.goodsInfo[ind-1].volume)
      let temp = JSON.parse(JSON.stringify(this.comm_form.goodsInfo))
      console.log('拷贝规格表',temp)
      console.log('打印单行复制后的规格表',this.comm_form.goodsInfo)
      this.$set(this.comm_form,'goodsInfo',temp)//
      console.log(this.comm_form)
    },
    // 添加规格按钮  添加多规格
    addBtn() {
      if (this.attrs.length == 2) {
        this.$message.error("最多添加两种规格");
      } else {
        this.clearAttr();
        this.createFormatBtn = true;
        this.showFormatEdit = true;
      }
    },
    // 立即生成
    generate() {
      if (this.attrs.length) {
        this.isGenerate = true;
        let data = {
          attrs: JSON.parse(JSON.stringify(this.attrs)),
        };
        data.attrs.forEach((ele) => {
          ele.detail = ele.detail.sort((a, b) => a > b);
        });
        data.attrs = data.attrs.sort((a, b) => a.value > b.value);
        if (!this.flag || this.flag == "false") {
          data.goodId = this.$route.query.id;
        }
        generateSpecification(data).then((res) => {
          this.comm_form.manySpecInfo = res.data.body;
          // console.log(res.data.body)
          this.comm_form.goodsInfo = res.data.body;
        });
      } else {
        this.$message.warning("请添加新规格");
      }
    },
    // 取消
    offAttrName() {
      this.showFormatEdit = false;
      this.createFormatBtn = true;
    },
    //清除规格
    clearAttr() {
      this.comm_form.attrsName = "";
      this.comm_form.attrsVal = "";
    },
    // 删除规格
    handleRemoveRole(row, index) {
      if (row.isDel != undefined && row.isDel != null) {
        this.$set(this.attrs[index], "isDel", 1);
      } else {
        this.attrs.splice(index, 1);
      }
    },
    // 删除属性
    handleRemove2(item, index) {
      item.splice(index, 1);
    },
    // 添加规则名称
    createAttrName() {
      if (this.comm_form.attrsName && this.comm_form.attrsVal) {
        let data = {
          value: this.comm_form.attrsName,
          detail: [this.comm_form.attrsVal],
        };
        this.$set(this.attrs, this.attrs.length, data);
        var hash = {};
        this.attrs = this.attrs.reduce(function (item, next) {
          hash[next.value] ? "" : (hash[next.value] = true && item.push(next));
          return item;
        }, []);
        this.clearAttr();
        this.showFormatEdit = false;
        this.createFormatBtn = true;
      } else {
        this.$message.warning("请添加完整的规格！");
      }
    },
    // 添加属性
    createAttr(num, idx, arr) {
      if (num) {
        if (arr.indexOf(num) > -1) {
          this.$message.warning("此属性已存在！");
        } else {
          this.attrs[idx].detail.push(num);
          let hash = {};
          this.attrs[idx].detail = this.attrs[idx].detail.reduce(function (
            item,
            next
          ) {
            hash[next] ? "" : (hash[next] = true && item.push(next));
            return item;
          },
          []);
        }
      } else {
        this.$message.warning("请添加属性！");
      }
    },
    buyMoreDiscountChange() {
      // 多买折扣状态改变
      this.comm_form.buyMoreDiscount = [];
      if (this.comm_form.isBuyMoreDiscount == 1) {
        this.$set(this.comm_form.buyMoreDiscount, 0, {
          buyMoreDiscountStartTime: "",
          buyMoreDiscountEndTime: "",
          buyMoreDiscountNum: "",
          buyMoreDiscountDiscount: "",
        });
      }
    },
    // 添加多买折扣规则
    addMoreDiscountBtn() {
      if (this.comm_form.buyMoreDiscount.length == 2) {
        this.$message.error("最多添加两种规则");
      } else {
        this.$set(
          this.comm_form.buyMoreDiscount,
          this.comm_form.buyMoreDiscount.length,
          {
            buyMoreDiscountStartTime: "",
            buyMoreDiscountEndTime: "",
            buyMoreDiscountNum: "",
            buyMoreDiscountDiscount: "",
          }
        );
      }
    },
    deliveryMethodChange(value) {
      // console.log(value);
      // console.log(this.comm_form.deliveryMethod)
      //邮寄模板选择
      if(value==1) {
 this.comm_form.deliveryTempId = "";
      }
     
      this.comm_form.deliveryType =
        this.comm_form.deliveryMethod.length == 2
          ? 2
          : this.comm_form.deliveryMethod.indexOf(1) > -1
          ? 1
          : this.comm_form.deliveryMethod.indexOf(0) > -1
          ? 0
          : ""; //配送方式
    },
    // 确定提交
    submit() {
      // console.log(this.attrs)
      // console.log(this.comm_form)
      this.$refs["comm_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        if(this.comm_form.specType === 1 && (!this.attrs.length)){
           this.$message({
            message: "选择多规格时,请自定义规格",
            type: "warning",
          });
        }
        this.submitForm();
      });
    },
    submitForm() {
      let data = JSON.parse(JSON.stringify(this.comm_form));
      if (data.specType == 1) {
        let goodsSpec = [];
        this.attrs.forEach((ele) => {
          let item = JSON.parse(JSON.stringify(ele.detail));
          item.forEach((el, index) => {
            let isHas = data.goodsInfo.filter(
              (e) => e.value1 == el || e.value2 == el
            );
            if (isHas.length == 0) {
              item.splice(index, 1);
            }
          });
          let dataSpec = JSON.parse(JSON.stringify(ele));
          (dataSpec.specName = dataSpec.value),
            (dataSpec.specValues = item.sort((a, b) => a > b).join(","));

          if (item.length) {
            goodsSpec.push(dataSpec);
          } else {
            if (dataSpec.isDel !== undefined && dataSpec.isDel !== null) {
              dataSpec.isDel = 1;
              goodsSpec.push(dataSpec);
            }
          }
        });
        goodsSpec = goodsSpec.sort((a, b) => a.specName > b.specName);
        data.goodsSpec = goodsSpec;
      }

      if (
        Object.prototype.toString.call(data.goodsClassifyId) == "[object Array]"
      ) {
        data.goodsClassifyId =
          data.goodsClassifyId[data.goodsClassifyId.length - 1]; //商品分类
      }
      data.buyMoreDiscount = JSON.stringify(data.buyMoreDiscount);
      if (!this.flag) data.id = this.$route.query.id;

      subProduct(data)
        .then((res) => {
          this.$message({
            message: "操作成功",

            type: "success",
          });
          this.$router.go(-1);
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.rulesBox >>> .el-tag {
  margin-right: 20px;
}
.autoWidth {
  width: 100%;
}
.inline {
  display: inline-block;
}
/deep/.el-upload-dragger{
  width: 144px;
  height: 144px;
}
/deep/.el-table--enable-row-transition .el-table__body td.el-table__cell{
  overflow: hidden;
}
</style>
